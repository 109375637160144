<template>
  <main id="top-section" class="flex-fill mx-auto">
    <div class="container">
      <h1 class="h4">Travel Clearance</h1>
      <br />
      <div class="clearfix">&nbsp;</div>
      <form novalidate class="bs-validate row g-3">
        <div class="bg-white row px-sl-4 py-3">
          <div class="row col-md-12 mb-3 border-bottom shadow">
            <div class="col-md-4">
              <label for="flightdate" class="form-label">Flight Date</label>
              <input type="date" class="form-control" value required />
            </div>
            <div class="col-md-4">
              <label for="destination" class="form-label"
                >Final Destination</label
              >
              <select
                name="destination"
                type="text"
                id="seldest"
                class="form-control"
                value
                required
              >
                <option selected disabled value>Select...</option>
              </select>
            </div>
            <div class="col-md-4">
              <label for="portofexit" class="form-label">Port of Exit</label>
              <select
                name="portofexit"
                type="text"
                id="seldest"
                class="form-control"
                value
                required
              >
                <option selected disabled value>Select...</option>
              </select>
            </div>
            <div class="clearfix">&nbsp;</div>
          </div>

          <div class="bg-white row p-xl-4 shadow">
            <header class="h5 mb-3 muted">Personal Details</header>
            <div class="col-md-4">
              <label for="surname" class="form-label">Surname</label>
              <input
                name="surname"
                type="text"
                class="form-control"
                placeholder="Surname"
                value
                required
                autofocus
              />
            </div>
            <div class="col-md-4">
              <label for="firstname" class="form-label">Firstname</label>
              <input
                name="firstname"
                type="text"
                class="form-control"
                placeholder="Firstname"
                value
                required
              />
            </div>
            <div class="col-md-4">
              <label for="middlename" class="form-label">Middlename</label>
              <input
                name="middlename"
                type="text"
                class="form-control"
                placeholder="Middlename"
                value
                required
              />
            </div>
            <div class="col-md-3">
              <label for="dateofbirth" class="form-label">Date of Birth</label>
              <input
                name="dateofbirth"
                type="date"
                class="form-control"
                value
                required
              />
            </div>
            <div class="col-md-3">
              <label for="gender" class="form-label">Gender</label>
              <select class="form-select" name="gender" id="selgender" required>
                <option selected disabled value>Select...</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div class="col-md-3">
              <label for="nationality" class="form-label">Nationality</label>
              <select
                name="nationality"
                type="text"
                class="form-select"
                required
              >
                <option selected disabled value>Select...</option>
              </select>
            </div>
            <div class="col-md-3">
              <label for="mobile" class="form-label">Mobile Number</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div name="preface" class="input-group-text prefix">+</div>
                </div>
                <input type="hidden" name="mobile" />
                <input
                  type="text"
                  class="form-control input-sm"
                  name="mobile_1"
                  maxlength="50"
                  required
                />
              </div>
            </div>
            <div class="col-md-4">
              <label for="passportno" class="form-label">Passport Number</label>
              <input type="text" class="form-control" required />
            </div>
            <div class="col-md-4">
              <label for="expirydate" class="form-label">Expiry Date</label>
              <input
                name="expirydate"
                type="date"
                class="form-control"
                value
                required
              />
            </div>
            <div class="col-md-4">
              <label for="occupation" class="form-label">Occupation</label>
              <input
                type="text"
                class="form-control"
                placeholder="Occupation"
                required
              />
            </div>
            <div class="col-md-8">
              <label for="address" class="form-label"
                >Residential address</label
              >
              <textarea
                type="text"
                cols="2"
                class="form-control"
                placeholder="Address in Country of residence"
                value=""
                required=""
              ></textarea>
            </div>

            <div class="col-md-4">
              <label for="state" class="form-label">State</label>
              <select
                name="state"
                type="text"
                class="form-select"
                value
                required
              >
                <option selected disabled value>Select...</option>
              </select>
            </div>
          </div>
          <div class="clearfix">&nbsp;</div>
          <div class="bg-white row p-xl-4 shadow">
            <header class="h5">Travel Details</header>
            <div class="row col-md-12">
              <div class="col-md-6">
                <label for="flight" class="form-label">Flight Carrier</label>
                <select class="form-control" value="" required="">
                  <option selected="" disabled="" value="">Select...</option>
                  <option>Africa World Airlines</option>
                  <option>Air Côte d'Ivoire</option>
                  <option>Air France</option>
                  <option>Air Peace</option>
                  <option>Antigua Airways</option>
                  <option>ASKY Airlines</option>
                  <option>British Airways</option>
                  <option>Delta Air Lines</option>
                  <option>Egypt Air</option>
                  <option>Emirates</option>
                  <option>Ethiopian Airlines</option>
                  <option>Etihad Airways</option>
                  <option>Kenya Airways</option>
                  <option>KLM</option>
                  <option>Lufthansa</option>
                  <option>Middle East Airlines</option>
                  <option>Qatar Airways</option>
                  <option>Royal Air Maroc</option>
                  <option>RwandAir</option>
                  <option>South African Airways</option>
                  <option>TAAG Angola Airlines</option>
                  <option>Turkish Airlines</option>
                  <option>United Airlines</option>
                  <option>Virgin Atlantic</option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="flightno" class="form-label">Flight Number</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="ABC1234"
                  value=""
                  required=""
                />
              </div>
              <div class="col-md-4">
                <label for="address" class="form-label"
                  >Destination Address:</label
                >
                <textarea
                  type="text"
                  cols="2"
                  class="form-control"
                  placeholder="Full detailed address in final destination"
                  value=""
                  required=""
                ></textarea>
              </div>
              <div class="col-md-4">
                <label for="state" class="form-label">State</label>
                <input type="text" class="form-control" value="" required="" />
              </div>
              <div class="col-md-4">
                <label for="mobile" class="form-label"
                  >Destination Mobile Number</label
                >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div name="prefix" class="input-group-text prefix">+</div>
                  </div>
                  <input type="hidden" name="mobile" />
                  <input
                    class="form-control input-sm"
                    name="mobile_2"
                    type="text"
                    maxlength="255"
                    required=""
                  />
                </div>
                <div class="clearfix">&nbsp;</div>
              </div>
            </div>
          </div>
          <div class="clearfix">&nbsp;</div>
          <div class="bg-white row p-x1-4 shadow">
            <div class="clearfix">&nbsp;</div>
            <header class="h5 mb-3 muted">Declaration</header>
            <div class="col-md-12 row">
              <label for="currency" class="form-label"
                >Are you in possession of currencies or Negotiable instruments
                or precious stones/metals in excess of <br />
                $10,000 (Ten Thousand US Dollars) or its equivalent?</label
              >
            </div>
            <div class="col-md-6 pt-4">
              <select class="form-control" name="currency" required="">
                <option disabled="" selected="" value="">--Select--</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div class="col-md-6 pt-4 currency_value" style="display: block">
              <input
                type="number"
                name="currency_value"
                class="form-control"
                placeholder="Specify value of currencies in your possession"
                required="required"
              />
            </div>

            <div class="col-12 pt-4">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="invalidCheck"
                  required=""
                />
                <label class="form-check-label" for="invalidCheck">
                  I agree that the information here are correct and up to date
                </label>
              </div>
            </div>
            <div class="clearfix">&nbsp;</div>
          </div>

          <div class="clearfix">&nbsp;</div>

          <div class="col-md-12 text-center mb-5">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#modalsuccess"
            >
              Submit form
            </button>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
export default {
  name: "departure",
};
</script>

<style scoped>
.container {
  width: 70%;
}
h1 {
  text-align: left;
}
.form-label {
  text-align: left;
}
.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #dde4ea;
  border-radius: 0.35rem;
  color: #1c0950;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.78rem 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

.form-select {
  appearance: none;
  background-color: #fff;
  background-image: url();
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #dde4ea;
  border-radius: 0.35rem;
  color: #1c0950;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.78rem 3rem 0.78rem 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
.mb-3 {
  margin-bottom: 1rem;
}
.row > * {
  margin-bottom: 0.5rem;
  text-align: left;
}
.input-group-text {
    align-items: center;
    background-color: #f1f4f8;
    border: 1px solid #dde4ea;
    border-radius: 0.35rem;
    color: #1c0950;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.78rem 1rem;
    text-align: center;
    white-space: nowrap;
}
</style>
