import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import ArrivalCard from '@/components/ArrivalCard.vue'
import DepartureCard from '@/components/DepartureCard.vue'
import OptionCard from '../views/OptionCard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/optioncard',
    name: 'optioncard',
    component: OptionCard,
  },
  {
    path: '/arrival',
    name: 'arrival',
    component: ArrivalCard,
  },
  {
    path: '/departure',
    name: 'departure',
    component: DepartureCard,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
