<template>
  <body
    class="header-sticky header-fixed"
    oncontextmenu="return false;"
    style="height: auto"
    data-aos-easing="ease-in-out-sine"
    data-aos-duration="700"
    data-aos-delay="0"
  >
    <div id="wrapper">
      <header id="header" class="shadow-xs">
        <div class="container position-relative">
          <nav
            class="navbar navbar-expand-lg navbar-light justify-content-lg-between justify-content-md-inherit"
          >
            <div class="align-items-start">
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarMainNav"
                aria-controls="navbarMainNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
              <img class="img" slot="imageProfile" :src="image" />
                <svg width="25" viewBox="0 0 20 20">
                  <path
                    d="M 19.9876 1.998 L -0.0108 1.998 L -0.0108 -0.0019 L 19.9876 -0.0019 L 19.9876 1.998 Z"
                  ></path>
                  <path
                    d="M 19.9876 7.9979 L -0.0108 7.9979 L -0.0108 5.9979 L 19.9876 5.9979 L 19.9876 7.9979 Z"
                  ></path>
                  <path
                    d="M 19.9876 13.9977 L -0.0108 13.9977 L -0.0108 11.9978 L 19.9876 11.9978 L 19.9876 13.9977 Z"
                  ></path>
                  <path
                    d="M 19.9876 19.9976 L -0.0108 19.9976 L -0.0108 17.9976 L 19.9876 17.9976 L 19.9876 19.9976 Z"
                  ></path>
                </svg>
              </button>

              <a class="navbar-brand" href="#">
                <img
                  src="../assets/frn.png"
                  width="110"
                  height="38"
                  alt="NIS"
                />
              </a>
            </div>

            <div
              class="collapse navbar-collapse navbar-animate-fadein justify-content-end"
              id="navbarMainNav"
            >
              <div class="navbar-xs d-none">
                <button
                  class="navbar-toggler pt-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarMainNav"
                  aria-controls="navbarMainNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <svg width="20" viewBox="0 0 20 20">
                    <path
                      d="M 20.7895 0.977 L 19.3752 -0.4364 L 10.081 8.8522 L 0.7869 -0.4364 L -0.6274 0.977 L 8.6668 10.2656 L -0.6274 19.5542 L 0.7869 20.9676 L 10.081 11.679 L 19.3752 20.9676 L 20.7895 19.5542 L 11.4953 10.2656 L 20.7895 0.977 Z"
                    ></path>
                  </svg>
                </button>

                <a class="navbar-brand" href="#">
                  <img
                    src="../assets/frn.png"
                    width="110"
                    height="38"
                    alt="NIS"
                  />
                </a>
              </div>

              <ul class="navbar-nav fs-6">
                <li class="nav-item">
                  <a class="nav-link scroll-to js-scrolltoified" href="#">
                    FEEDBACK
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link scroll-to js-scrolltoified" href="#">
                    FAQS
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link scroll-to js-scrolltoified" href="#">
                    CONTACT
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div id="js_header_spacer" style="height: 80px">
        <!-- spacer added by header js -->
      </div>

      <div
        class="section bg-light overlay-dark overlay-opacity-7 bg-cover lazy"
        style="
          background-size: cover;
          background-image: url('../assets/runway.png,)
        "
        data-loaded="true"
      >
        <div class="container position-relative z-index-2">
          <div
            class="py-5 text-center text-white aos-init aos-animate"
            data-aos="fade-in"
            data-aos-delay="0"
            data-aos-offset="0"
          >
            <h1 class="display-5 fw-bold mb-0 fw-light">
              <b class="text-success">IMMIGRATION TRAVEL PRE-CLEARANCE </b>
              <br /><span
                class="typed js-typified"
                data-typed-string="Smart|Fast|Flexible|Reliable"
                data-typed-speed-forward="70"
                data-typed-speed-back="50"
                data-typed-back-delay="800"
                data-typed-loop-times="infinite"
                data-typed-smart-backspace="true"
                data-typed-shuffle="false"
                data-typed-cursor="|"
                id="rand_Het"
                >Fast</span
              ><span class="typed-cursor" aria-hidden="true">|</span>
            </h1>

            <p class="h5 fw-normal mb-0">Online Arrival/Departure Card</p>
            <div class="clearfix py-6">
              <button
                type="button"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#modalLogin"
              >
                &nbsp; LOGIN &nbsp;
              </button>
            </div>
          </div>
        </div>
      </div>

      <footer id="footer" class="footer-dark">
        <div class="container clearfix fw-light text-center-xs">
          <div class="fs-6 py-2 float-start float-none-xs m-0-xs">
            © 2022 - NIGERIA IMMIGRATION SERVICE
          </div>
        </div>
      </footer>
    </div>

    <div
      class="modal fade"
      id="modalLogin"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalCenteredLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalCenteredLabel">
              Begin Clearance Here:
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form
              
              id="form"
              
            >
              <div class="form-floating mb-3">
                <input
                  v-model="loginForm.passportno"
                  type="text"
                  class="form-control"
                  placeholder="Passport"
                  name="passportno"
                  id="passno"
                />
                <label for="pptno">Passport Number</label>
              </div>
              <div class="d-grid mb-5">
                <button
                  v-on:click="doLogin()"
                  type="submit"
                  class="btn btn-lg btn-success"
                 
                >
                  <svg
                    class="rtl-flip"
                    width="18px"
                    height="18px"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    ></path>
                  </svg>
                  <input type="hidden" name="submit" value="submit" />Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>
<script>
import router from '@/router';
export default {
  name: "HomePage",
  data() {
    return {
      loginForm: {
        passportno: null,
        image: "../assets/runway.png",
      },
    };
  },
  methods: {
    doLogin(e) {
      console.log("doLogin called", this.loginForm.passportno);
    
      var y = this.loginForm.passportno;
      var letterNumber = /^[0-9a-zA-Z]+$/;
      if (y == null || y == "") {
        alert("Invalid Passport Number");
        
        return false;
      }
      if (y.length < 5 || y.length > 12) {
        alert("Passport Number is incorrect");
     
        return false;
      }
     
      this.$router.push(name="/optioncard");
      window.location.reload();
    
    },
  },
};
</script>
