<template>

    <div>
        
        <OptionCard />
    </div>
    
</template>

<script>


import OptionCard from '@/components/OptionCard.vue'

export default {
    components: {OptionCard },
}
</script>
