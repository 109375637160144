import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import ArrivalCardVue from './components/ArrivalCard.vue';
import DepartureCardVue from './components/DepartureCard.vue';
import VueRouter from 'vue-router';

Vue.use(VueAxios,axios)
Vue.config.productionTip = false

// const router = new VueRouter({
//   routes: [
//     { path: '/optioncard', component: OptionCard },
//     { path: '/arrival', component: ArrivalCard },
//     { path: '/departure', component: DepartureCard },
//     { path: '/', redirect: '/home' }
//   ]
// })

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
