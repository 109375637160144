<template>
  <div id="app">
    <nav>
      
    </nav>
    <router-view/>
   
  </div>
</template>

<script>
import DepartureCard from './components/DepartureCard.vue';
import HomePage from './components/HomePage.vue'

  export default {
      name: "App",
      components: {
        HomePage,
  },
};
</script>

<style>
#app {
  font-family: Inter,Rubik,Open Sans,Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
