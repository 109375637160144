<template>
  <div>
    <main id="middle" class="flex-fill mx-auto">
      <div class="container">
        <div class="sections p-4 mb-5">
          <div class="text-center mb-5">
            <h2 class="mb-5 fw-bold">Select an option:</h2>
          </div>
          <div class="row g-4">
            <div class="col-lg-6">
              <div class="bg-warning rounded-xl p-5 shadow text-center">
                <h3 class="h4">
                  <span class="display-4 fw-medium">Departure</span>
                </h3>
                <p
                  class="badge bg-gray-500 text-dark rounded-pill fs-6 fw-bold px-3 py-2 mb-5"
                >
                  Card
                </p>
                <ul class="list-unstyled mb-5">
                  <li class="list-item py-1">Leaving Nigeria</li>
                </ul>
                <a
                  href="/departure"
                  class="btn w-100 btn-pill btn-primary bg-gradient-primary"
                >
                  Fill Form
                </a>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="bg-gray-200 rounded-xl p-5 shadow text-center">
                <h3 class="h4">
                  <span class="pricetable-count display-4 fw-medium"
                    >Arrival</span
                  >
                </h3>
                <p
                  class="badge bg-danger-soft rounded-pill fs-6 fw-bold px-3 py-2 mb-5"
                >
                  Card
                </p>
                <ul class="list-unstyled mb-5">
                  <li class="list-item py-1">Coming to Nigeria</li>
                </ul>
                <a
                  href="/arrival"
                  class="btn w-100 btn-pill btn-danger bg-gradient-danger"
                >
                  Fill Form
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>


export default {
  name: 'optioncard',

};

</script>

<style scoped>

    .container {
        width: 70%;
    }
</style>
